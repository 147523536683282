import { useState, useEffect } from 'react';

export const useCookieBannerState = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const runningInApp = searchParams.get('mm_app');
  const [cookieBannerVisible, setCookieBannerVisible] = useState(
    localStorage.getItem('cookie-banner-state') !== 'cleared' && !runningInApp
  );

  useEffect(() => {
    const handleCookieBannerEvent = (event: Event) => {
      const customEvent = event as CustomEvent<{ cookieBannerVisible: boolean }>;
      if (customEvent.detail && typeof customEvent.detail.cookieBannerVisible !== 'undefined') {
        setCookieBannerVisible(customEvent.detail.cookieBannerVisible);
      }
    };
    window.addEventListener('cookieBannerUpdate', handleCookieBannerEvent);
    return () => {
      window.removeEventListener('cookieBannerUpdate', handleCookieBannerEvent);
    };
  }, []);

  const updateCookieBannerState = (visible: boolean) => {
    setCookieBannerVisible(visible);
    localStorage.setItem('cookie-banner-state', visible ? 'visible' : 'cleared');
    const event = new CustomEvent('cookieBannerUpdate', {
      detail: { cookieBannerVisible: visible },
    });
    window.dispatchEvent(event);
  };

  return { cookieBannerVisible, setCookieBannerVisible: updateCookieBannerState };
};
