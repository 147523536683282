import { useClickOutsideElement } from 'mypages-library';
import { useRef, useState } from 'react';

export const useTrackPopOver = () => {
  const [infoTextVisible, setInfoTextVisible] = useState(false);
  const onClickInfo = () => {
    setInfoTextVisible(true);
    setTimeout(() => setInfoTextVisible(false), 10000);
  };
  const popOver = useRef(null);
  const closePreview = () => {
    setInfoTextVisible(false);
  };
  useClickOutsideElement(popOver, closePreview);

  return { infoTextVisible, onClickInfo };
};
