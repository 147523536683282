import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Transition } from 'react-transition-group';
import { appConstants, useClickOutsideElement } from 'mypages-library';

import styles from './SidePanel.module.scss';

function SidePanel({ children, visible, onClose }) {
  const sidePanel = useRef(null);
  const { isIos } = appConstants;

  if (visible) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }

  const clickOutsideFunc = () => {
    if (visible) {
      onClose();
    }
  };
  useClickOutsideElement(sidePanel, clickOutsideFunc);

  return (
    <Transition
      in={visible}
      timeout={{
        appear: 0,
        enter: 0,
        exit: 350,
      }}
      unmountOnExit
      appear
    >
      {state => {
        const className = classNames(styles.panelWrapper, styles[`panel-wrapper-${state}`], {
          [styles.iosPadding]: isIos,
        });
        return (
          <section className={className} ref={sidePanel} id="sidePanel" role="dialog">
            {children}
          </section>
        );
      }}
    </Transition>
  );
}

export default SidePanel;

SidePanel.propTypes = {
  children: PropTypes.node,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

SidePanel.defaultProps = {
  visible: false,
  children: {},
  onClose: () => {},
};
