import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './Flag.module.scss';

type FlagProps = {
  country: string;
  lazyLoadImgWhenScolling?: boolean;
  className?: string;
};

const Flag = ({ country, className, lazyLoadImgWhenScolling }: FlagProps) => {
  const [path, setPath] = useState('');
  const imgRef = useRef(null);
  const getPathLazy = async () => {
    const flagIcon = await import(`./flagicons/${country?.toLowerCase()}.svg`);
    setPath(flagIcon.default);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        getPathLazy();
        observer.disconnect();
      }
    });

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }
    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, [imgRef]);

  useEffect(() => {
    if (!lazyLoadImgWhenScolling) {
      getPathLazy();
    }
  }, [country]);
  return (
    <img
      id="countryFlag"
      ref={imgRef}
      src={path ?? ''}
      alt="country flag"
      className={classNames(styles.fi, className)}
    />
  );
};

export default Flag;

Flag.defaultProps = {
  className: '',
  lazyLoadImgWhenScolling: false,
};
