import React from 'react';
import classNames from 'classnames';
import { Icon, PopOver, SelectionField } from 'mypages-library';
import { t } from 'i18n/translate';
import { useTrackPopOver } from 'common/useTrackPopOver';
import { LocaleToCountryStringMappingEntries } from '../CreateAccount';
import styles from './CountrySelector.module.scss';

interface CountrySelectorProps {
  defaultValue: string;
  disabled: boolean;
  onSelectionFieldChange: (value: string) => void;
  entries: LocaleToCountryStringMappingEntries[];
}

const CountrySelector: React.FC<CountrySelectorProps> = ({
  defaultValue,
  disabled,
  onSelectionFieldChange,
  entries,
}) => {
  const { infoTextVisible, onClickInfo } = useTrackPopOver();

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <p className={styles.title}>{t('MPL_CREATE_ACCOUNT_COUNTRY_SELECTOR_TITLE')}</p>
        <div className={styles.popoverContainer}>
          <Icon icon="icon-help-contextual" size="small" className={styles.icon} onClick={onClickInfo} tabIndex={-1} />
          {infoTextVisible && (
            <PopOver
              visible={infoTextVisible}
              className={classNames(styles.popover, infoTextVisible && styles.popoverVisible)}
            >
              <p className={styles.infoText}>{t('MPL_CREATE_ACCOUNT_COUNTRY_SELECTOR_INFORMATION')}</p>
            </PopOver>
          )}
        </div>
      </div>
      <SelectionField
        name="country-picker"
        onChange={onSelectionFieldChange}
        defaultValue={defaultValue}
        disabled={disabled}
        dataTestId="countrySelector"
      >
        {entries.map(([locale, country]) => (
          // @ts-ignore
          <p key={locale} value={locale} id={`countrySelectorOption${country}`}>
            {country}
          </p>
        ))}
      </SelectionField>
    </div>
  );
};

export default CountrySelector;
