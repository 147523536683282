import React from 'react';
import { Button } from 'mypages-library';
import { t } from 'i18n/translate';
import SubsidiaryAppLogo from 'components/SubsidiaryAppLogo/SubsidiaryAppLogo';
import styles from './TermsAndCondition.module.scss';

type TermsAndConditionProps = {
  terms: string | null | undefined;
  error: string;
  setShowTerms: (showTerms: boolean) => void;
};
export const addTargetBlankToLinks = (html: string) => {
  if (!html) return '';
  return html.replace(/<a\s+((?!target=)[^>]*?)href=([^>]*?)>/g, '<a $1href=$2 target="_blank">');
};

const TermsAndCondition = ({ terms, error, setShowTerms }: TermsAndConditionProps) => (
  <div className={styles.termsContainer}>
    <div className={styles.textContainer}>
      <SubsidiaryAppLogo />
      {error && <p>{error}</p>}
      {/* eslint-disable-next-line react/no-danger */}
      {terms && <div dangerouslySetInnerHTML={{ __html: addTargetBlankToLinks(terms) }} />}
    </div>

    <div className={styles.optionButtons}>
      <Button
        dataTestId="closeTermsAndConditions"
        text={t('MPL_CLOSE')}
        onClick={() => setShowTerms(false)}
        className={styles.optionButton}
        flat
        cancel
      />
    </div>
  </div>
);
export default TermsAndCondition;
