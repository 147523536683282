import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { fetchWithFailover } from 'default-client/DefaultClient';
import { trackEvent } from 'common/google/util';
import { simpleSentryLog } from 'common/utilities/simpleSentryLog';

// eslint-disable-next-line no-shadow
export enum VerifyAccountStatus {
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Invalid = 'INVALID',
  UndefinedSecret = 'UNDEFINED_SECRET',
  Error = 'ERROR',
}

const track = (eventLabel: string) => {
  trackEvent({
    eventCategory: 'site login',
    eventAction: 'verify account',
    eventLabel,
  });
};

const useVerifyAccount = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const secret = queryParams.get('secret');
  const [status, setStatus] = useState<VerifyAccountStatus>(VerifyAccountStatus.InProgress);

  const isSuccessfulValidation = async (response: Response | undefined) => response?.ok && response?.status === 200;

  const updateStatus = (newStatus: VerifyAccountStatus) => {
    setStatus(newStatus);
    track(newStatus);
  };

  const verifyAccount = async () => {
    try {
      const verifyAccountResponse = await fetchWithFailover(`/webaccount/public/verify`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          APPLICATION_ID: process.env.REACT_APP_APPLICATION_ID,
        },
        body: JSON.stringify({ secret }),
      });

      const success = await isSuccessfulValidation(verifyAccountResponse);

      if (success) {
        updateStatus(VerifyAccountStatus.Completed);
      } else {
        updateStatus(VerifyAccountStatus.Invalid);
      }
    } catch (e) {
      simpleSentryLog('Verify account failed', { e });
      updateStatus(VerifyAccountStatus.Error);
    }
  };

  useEffect(() => {
    if (secret) {
      verifyAccount();
    } else {
      updateStatus(VerifyAccountStatus.UndefinedSecret);
    }
  }, [secret]);

  return { status };
};

export default useVerifyAccount;
