import React, { useRef } from 'react';
import classNames from 'classnames';
import { Icon, useClickOutsideElement } from 'mypages-library';
import { Country } from 'utils/countries';
import DropDownBox from '../DropDownBox/DropDownBox';
import Flag from '../Flag/Flag';
import styles from './PhoneSelectButton.module.scss';

interface MenuController {
  menuExpanded: boolean;
  setMenuExpanded: (expanded: boolean) => void;
}

interface PhoneSelectButtonProps {
  country: Country | null;
  children?: React.ReactNode;
  menuController: MenuController;
}

const PhoneSelectButton: React.FC<PhoneSelectButtonProps> = ({ country, children, menuController }) => {
  const { menuExpanded, setMenuExpanded } = menuController;
  const refNode = useRef<HTMLDivElement | null>(null);
  useClickOutsideElement(refNode, () => setMenuExpanded(false));
  const buttonArrow = menuExpanded ? 'icon-arrow-up' : 'icon-arrow-down';

  return (
    <div className={classNames(styles.dropDownButton, menuExpanded && styles.expanded)} ref={refNode}>
      <button
        tabIndex={-1}
        type="button"
        onClick={() => setMenuExpanded(!menuExpanded)}
        className={classNames(!children && styles.disabled, styles.phoneSelectButton)}
        id="phoneSelectButton"
      >
        <Flag country={country?.code ?? ''} className={styles.buttonLabel} />
        <p className={styles.buttonLabel}>{country?.dialCode ?? ''}</p>
        {children && <Icon icon={buttonArrow} className={styles.buttonArrow} size="small" />}
      </button>
      <DropDownBox className={menuExpanded ? styles.maxheight : ''} expanded={menuExpanded}>
        {children}
      </DropDownBox>
    </div>
  );
};

export default PhoneSelectButton;
