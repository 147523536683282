import { ga } from 'mypages-library';

const defaultCategory = 'mypages login';

interface TrackEventParams {
  eventCategory?: string;
  eventAction: string;
  eventLabel?: string;
  eventValue?: string | number;
}

export const trackEvent = ({ eventCategory, eventAction, eventLabel, eventValue }: TrackEventParams) => {
  const eventParameters: TrackEventParams = {
    eventCategory: eventCategory || defaultCategory,
    eventAction,
  };
  if (eventLabel) {
    eventParameters.eventLabel = eventLabel;
  }
  if (eventValue) {
    eventParameters.eventValue = eventValue;
  }

  ga.trackEvent(eventParameters);
};
