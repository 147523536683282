import React from 'react';
import verified from 'images/wbaccount/verification_sent.png';
import { t } from 'i18n/translate';
import GenericWebAccountStatus from './GenericWebAccountStatus';

export type WebAccountCreatedProps = {
  email: string;
};

const WebAccountCreated = ({ email }: WebAccountCreatedProps) => (
  <GenericWebAccountStatus
    header={t('MPL_CREATE_ACCOUNT_VERIFY_EMAIL_TITLE')}
    body={t('MPL_CREATE_ACCOUNT_VERIFY_EMAIL_DESC', { email })}
    image={verified}
    altText="Web account verified"
    dataTestId="successVerificationEmailSent"
  />
);

export default WebAccountCreated;
