import React from 'react';
import { Button } from 'mypages-library';
import { useHistory } from 'react-router-dom';
import congratulation from 'images/wbaccount/congratulations.png';
import { t } from 'i18n/translate';
import styles from './WebAccountCreated.module.scss';
import GenericWebAccountStatus from './GenericWebAccountStatus';

const WebAccountCreatedFromInvitation = () => {
  const history = useHistory();

  const navigateToLogin = () => {
    history.push('/login');
  };

  return (
    <GenericWebAccountStatus
      header={t('MPL_CREATE_ACCOUNT_VERIFY_TITLE')}
      body={t('MPL_ACCOUNT_CREATED_FROM_INVITE_DESC')}
      image={congratulation}
      altText="Web account created from invitation"
      dataTestId="fromInviteSuccess"
    >
      <Button
        text={t('MPL_CREATE_ACCOUNT_VERIFY_LOG_IN')}
        primary
        onClick={navigateToLogin}
        className={styles.loginBtn}
        dataTestId="fromInviteSuccessLoginBtn"
      />
    </GenericWebAccountStatus>
  );
};

export default WebAccountCreatedFromInvitation;
