import { useEffect, useState } from 'react';
import { fetchWithFailover } from 'default-client/DefaultClient';
import { baseURL } from 'common/wildflyAuthentication';
import { getQueryParam } from 'common/urlParser';
import { trackEvent } from 'common/google/util';

// This is a bug in eslint-plugin-import
// eslint-disable-next-line no-shadow
export enum ResumeStates {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
}

interface UseResumeSessionResult {
  resumeState: ResumeStates;
}

export const useResumeSession = (): UseResumeSessionResult => {
  const [resumeState, setResumeState] = useState<ResumeStates>(ResumeStates.IN_PROGRESS);

  const track = (eventLabel: string) => {
    trackEvent({
      eventCategory: 'site login',
      eventAction: 'resume session',
      eventLabel,
    });
  };

  useEffect(() => {
    const resume = async () => {
      try {
        const giid = getQueryParam('installation', window.location.search);
        if (giid) {
          sessionStorage.setItem('app.settings.selected', JSON.stringify({ installationId: giid }));
        }
        // Refresh JWT token
        const tokenResponse = await fetchWithFailover('/auth/token', {
          method: 'POST',
          credentials: 'include',
          headers: {
            APPLICATION_ID: process.env.REACT_APP_APPLICATION_ID as string,
            ACCEPT: 'application/json',
          },
        });
        if (!tokenResponse || tokenResponse.status !== 200) {
          track('Token refresh failed');
          setResumeState(ResumeStates.FAIL);
          return;
        }

        // Extend mgw session
        const extendResponse = await fetch(`${baseURL()}/session/extend`, {
          credentials: 'include',
          redirect: 'manual',
        });
        if (extendResponse.status !== 200) {
          track('Extend session failed');
          setResumeState(ResumeStates.FAIL);
          return;
        }
        track('success');
        setResumeState(ResumeStates.SUCCESS);
      } catch (error) {
        track('error');
        setResumeState(ResumeStates.FAIL);
      }
    };
    resume();
  }, []);

  return { resumeState };
};
