type ResolveParams = {
  targetId: string | null;
  lang: string;
};

type ResolveFunction = (params: ResolveParams) => Promise<string>;

interface Target {
  name: string;
  resolve: ResolveFunction;
}

const targets: Target[] = [
  {
    name: 'accountinformation',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/accountinformation.html`,
  },
  {
    name: 'adddevice',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/adddevice`,
  },
  {
    name: 'soundsettings',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/systemsettings?action=open&sidePanel=soundSettings`,
  },
  {
    name: 'petsettings',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/systemsettings?action=open&sidePanel=petSettings`,
  },
  {
    name: 'armedstaysettings',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/systemsettings?action=open&sidePanel=armedStaySettings`,
  },
  {
    name: 'codewordssettings',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/systemsettings?action=open&sidePanel=codeWordsSettings`,
  },
  {
    name: 'broadbandstatus',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/systemsettings?action=open&sidePanel=remoteAccessSettings`,
  },
  {
    name: 'installationAddress',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/systemsettings?action=open&sidePanel=installationAddress`,
  },
  {
    name: 'mediaretention',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/systemsettings?action=open&sidePanel=mediaretention`,
  },
  {
    name: 'install',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/install`,
  },
  {
    name: 'installationwizard',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/systeminstall`,
  },
  {
    name: 'invoicehistory',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/invoices`,
  },
  {
    name: 'manual',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/manuals`,
  },
  {
    name: 'myservice',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/services`,
  },
  {
    name: 'renovation',
    resolve: async () => `/renovation`,
  },
  {
    name: 'renovationfinish',
    resolve: async () => `/renovation`,
  },
  {
    name: 'renovationupdate',
    resolve: async () => `/renovation`,
  },
  {
    name: 'settings',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/systemsettings`,
  },
  {
    name: 'start',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/status`,
  },
  {
    name: 'users',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/users`,
  },
  {
    name: 'verifyinstallation',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/status?openWizard=VERIFY_SYSTEM`,
  },
  {
    name: 'webshop',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/ecommerce`,
  },
  {
    name: 'webshopproduct',
    resolve: async ({ targetId, lang }: ResolveParams) => `/${lang}/ecommerceProduct/${targetId}`,
  },
  {
    name: 'webshopbattery',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/ecommerceBattery`,
  },
  {
    name: 'webshopsms',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/ecommerceSms`,
  },
  {
    name: 'arlolink',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/services/addArlo`,
  },
  {
    name: 'arlorelink',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/services/relinkArlo`,
  },
  {
    name: 'calibratelock',
    resolve: async ({ targetId, lang }: ResolveParams) =>
      `/${lang}/featuredetails/SmartLock?action=calibrate&deviceLabel=${targetId}`,
  },
  {
    name: 'linksvk',
    resolve: async ({ targetId, lang }: ResolveParams) => `/${lang}/devices/Keypad?action=link&deviceLabel=${targetId}`,
  },
  {
    name: 'smartlock',
    resolve: async ({ targetId, lang }: ResolveParams) =>
      `/${lang}/devices/SmartLock?action=link&deviceLabel=${targetId}`,
  },
  {
    name: 'batterywizard',
    resolve: async ({ targetId }: ResolveParams) => {
      if (targetId) {
        return `/battery?deviceLabel=${targetId}`;
      }
      return `/battery`;
    },
  },
  {
    name: 'renovationwizard',
    resolve: async ({ targetId }: ResolveParams) => {
      if (targetId) {
        return `/renovation?scenario=${targetId}`;
      }
      return `/renovation`;
    },
  },
  {
    name: 'replace',
    resolve: async () => `/replace`,
  },
  {
    name: 'deleteaccount',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/deleteaccount`,
  },
  {
    name: 'workswith',
    resolve: async ({ targetId, lang }: ResolveParams) => {
      if (targetId) {
        return `/${lang}/devices/worksWithPartnerConnect?partner=${targetId}`;
      }
      return `/${lang}/devices/worksWithPartnerConnect`;
    },
  },
  {
    name: 'verifysystemwizard',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/status?openWizard=VERIFY_SYSTEM`,
  },
  {
    name: 'serviceVisits',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/serviceVisits`,
  },
  {
    name: 'duressguide',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/devices?openWizard=DURESS`,
  },
  {
    name: 'systeminstall',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/status?openWizard=SYSTEMINSTALL`,
  },
  {
    name: 'verificationwizard',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/status?openWizard=VERIFICATION`,
  },
  {
    name: 'onboardretailwizard',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/status?openWizard=ONBOARDING`,
  },
  {
    name: 'status',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/status`,
  },
  {
    name: 'livechat',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/livechat`,
  },
  {
    name: 'routines',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/routines`,
  },
  {
    name: 'eventlog',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/eventlog`,
  },
  {
    name: 'recommendus',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/referral`,
  },
  {
    name: 'openbatterywizard',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/status?openWizard=BATTERY`,
  },
  {
    name: 'openrenovationwizard',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/status?openWizard=RENOVATION`,
  },
  {
    name: 'openreplacewizard',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/status?openWizard=REPLACE`,
  },
  {
    name: 'replacegatewaywizard',
    resolve: async () => `/replace-gateway`,
  },
  {
    name: 'currentusernotifications',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/users?action=open&sidePanel=notificationSettings`,
  },
  {
    name: 'workswithlink',
    resolve: async ({ targetId, lang }: ResolveParams) =>
      `/${lang}/devices/worksWithPartnerConnect?action=decide-if-relink-unlink&partner=${targetId}`,
  },
  {
    name: 'keyfobinstallation',
    resolve: async ({ targetId, lang }: ResolveParams) => `/${lang}/users/codestags?${targetId}&openkeyfob`,
  },
  {
    name: 'faq',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/faq`,
  },
  {
    name: 'actionplan',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/users/emergencyContact`,
  },
  {
    name: 'cameras',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/cameras`,
  },
  {
    name: 'vacationmode',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/systemsettings?action=open&sidePanel=vacationModeSettings`,
  },
  {
    name: 'firmwareupgrade',
    resolve: async ({ lang }: ResolveParams) => `/${lang}/devices/CentralUnit?sidePanel=firmwareUpgrade&action=open`,
  },
];

export const getRedirectTarget = async (targetName: string, targetId: string | null, lang: string) => {
  const target = targets.find(t => t.name === targetName);
  if (!target) {
    return null;
  }

  return target.resolve({ targetId, lang });
};
