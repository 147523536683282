export interface Country {
  name: string;
  dialCode: string;
  code: string;
  preferred?: boolean;
  secondary?: boolean;
  areaCodes?: Array<string>;
  countryCode?: string;
  localName?: string;
  partOf?: string[];
  trunkPrefix?: string;
  example?: string;
}

const i18nLangToLocale = {
  nlbe: 'nl_BE',
  frbe: 'fr_BE',
  dk: 'da_DK',
  de: 'de_DE',
  fr: 'fr_FR',
  nl: 'nl_NL',
  no: 'no_NO',
  fi: 'fi_FI',
  se: 'sv_SE',
  uk: 'en_GB',
} as const;

const fallbackCountry = { name: 'Sweden', dialCode: '+46', code: 'se', preferred: true, trunkPrefix: '0' };

export const getDefaultCountry = (language: string) => i18nLangToLocale[language as keyof typeof i18nLangToLocale];

export const findPreselectedCountry = (presetLanguage: string) =>
  countries.find(country => country.code.split(',').includes(presetLanguage)) || fallbackCountry;

export const findCountryDialCodeMatch = (phoneNumber: string | undefined) => {
  if (!phoneNumber) {
    return null;
  }

  if (!phoneNumber.startsWith('00') && !phoneNumber.startsWith('+')) {
    return null;
  }

  const phoneToCheck = phoneNumber?.startsWith('00') ? `+${phoneNumber.substring(2)}` : phoneNumber;
  const filtered = countries.filter(country => phoneToCheck.startsWith(country.dialCode));
  const country = filtered.length > 0 ? filtered[0] : null;

  return country;
};

const omitTrunkPrefixAndAddCountryCode = (phoneNumber: string, country: Country | null) => {
  const trunkPrefix = country?.trunkPrefix ?? '';
  if (trunkPrefix && phoneNumber.startsWith(trunkPrefix)) {
    return phoneNumber.substring(trunkPrefix.length);
  }
  return phoneNumber;
};

export const removePhoneNumberCountryCode = (phoneNumber: string, country: Country | null) => {
  const dialCode = country?.dialCode ?? null;
  if (dialCode && phoneNumber.startsWith('00')) {
    return phoneNumber.substring(dialCode.length + 1);
  }
  if (dialCode && phoneNumber.startsWith('+')) {
    return phoneNumber.substring(dialCode.length);
  }
  return phoneNumber;
};

export const formatPhoneForApi = (phoneNumberInput: string, country: Country) => {
  const phoneWithoutSpacesAndDashes = phoneNumberInput.replace(/[\s-]/g, '');
  const withoutTrunkPrefix = omitTrunkPrefixAndAddCountryCode(phoneWithoutSpacesAndDashes, country);
  const formattedCountryCode = country.dialCode.replace('+', '00');
  return `${formattedCountryCode}${withoutTrunkPrefix}`;
};

export const countries: Country[] = [
  {
    name: 'Belgium',
    dialCode: '+32',
    code: 'frbe,nlbe',
    preferred: true,
    trunkPrefix: '0',
    example: '470123456',
  },
  { name: 'Denmark', dialCode: '+45', code: 'dk', preferred: true, example: '32123456' },
  {
    name: 'Germany',
    dialCode: '+49',
    code: 'de',
    preferred: true,
    trunkPrefix: '0',
    example: '15123456789',
  },
  {
    name: 'France',
    dialCode: '+33',
    code: 'fr',
    preferred: true,
    trunkPrefix: '0',
    example: '612345678',
  },
  {
    name: 'Netherlands',
    dialCode: '+31',
    code: 'nl',
    preferred: true,
    trunkPrefix: '0',
    example: '612345678',
  },
  {
    name: 'Norway',
    dialCode: '+47',
    code: 'no',
    localName: 'Norge',
    preferred: true,
    example: '40612345',
  },
  {
    name: 'Finland',
    dialCode: '+358',
    code: 'fi',
    preferred: true,
    trunkPrefix: '0',
    example: '412345678',
  },

  {
    name: 'Sweden',
    dialCode: '+46',
    code: 'se',
    preferred: true,
    trunkPrefix: '0',
    example: '701234567',
  },
  {
    name: 'United Kingdom',
    dialCode: '+44',
    code: 'uk',
    preferred: true,
    trunkPrefix: '0',
    example: '7400123456',
  },
  // ----------------------------------------------------------
  { name: 'Afghanistan', dialCode: '+93', code: 'AF', localName: 'افغانستان' },
  { name: 'Albania', dialCode: '+355', code: 'AL', localName: 'Shqipëria' },
  { name: 'Algeria', dialCode: '+213', code: 'DZ', localName: 'Dzayer' },
  { name: 'American Samoa', dialCode: '+1684', code: 'AS', partOf: ['US'] },
  { name: 'Andorra', dialCode: '+376', code: 'AD', localName: 'Andorra' },
  { name: 'Angola', dialCode: '+244', code: 'AO', localName: 'Angola' },
  {
    name: 'Anguilla',
    dialCode: '+1264',
    code: 'AI',
    localName: 'Anguilla',
    partOf: ['GB'],
  },
  { name: 'Antarctica', dialCode: '+672', code: 'AQ' },
  { name: 'Antigua and Barbuda', dialCode: '+1268', code: 'AG' },
  { name: 'Argentina', dialCode: '+54', code: 'AR' },
  { name: 'Armenia', dialCode: '+374', code: 'AM' },
  { name: 'Aruba', dialCode: '+297', code: 'AW', partOf: ['NL'] },
  { name: 'Australia', dialCode: '+61', code: 'AU' },
  { name: 'Austria', dialCode: '+43', code: 'AT' },
  { name: 'Azerbaijan', dialCode: '+994', code: 'AZ' },
  { name: 'Bahamas', dialCode: '+1242', code: 'BS' },
  { name: 'Bahrain', dialCode: '+973', code: 'BH' },
  { name: 'Bangladesh', dialCode: '+880', code: 'BD', localName: 'বাংলাদেশ' },
  { name: 'Barbados', dialCode: '+1246', code: 'BB' },
  { name: 'Belarus', dialCode: '+375', code: 'BY' },

  { name: 'Belize', dialCode: '+501', code: 'BZ' },
  { name: 'Benin', dialCode: '+229', code: 'BJ', localName: 'Bénin' },
  { name: 'Bermuda', dialCode: '+1441', code: 'BM', partOf: ['GB'] },
  { name: 'Bhutan', dialCode: '+975', code: 'BT', localName: 'འབྲུག་ཡུལ' },
  { name: 'Bolivia, Plurinational State of', dialCode: '+591', code: 'BO' },
  { name: 'Bosnia and Herzegovina', dialCode: '+387', code: 'BA' },
  { name: 'Botswana', dialCode: '+267', code: 'BW' },
  { name: 'Brazil', dialCode: '+55', code: 'BR' },
  {
    name: 'British Indian Ocean Territory',
    dialCode: '+246',
    code: 'IO',
    partOf: ['GB'],
  },
  { name: 'Brunei Darussalam', dialCode: '+673', code: 'BN', localName: 'بروني' },
  { name: 'Bulgaria', dialCode: '+359', code: 'BG' },
  { name: 'Burkina Faso', dialCode: '+226', code: 'BF' },
  { name: 'Burundi', dialCode: '+257', code: 'BI' },
  { name: 'Cambodia', dialCode: '+855', code: 'KH' },
  { name: 'Cameroon', dialCode: '+237', code: 'CM' },
  { name: 'Canada', dialCode: '+1', code: 'CA' },
  { name: 'Cape Verde', dialCode: '+238', code: 'CV' },
  { name: 'Cayman Islands', dialCode: '+345', code: 'KY', partOf: ['GB'] },
  { name: 'Central African Republic', dialCode: '+236', code: 'CF' },
  { name: 'Chad', dialCode: '+235', code: 'TD' },
  { name: 'Chile', dialCode: '+56', code: 'CL' },
  { name: 'China', dialCode: '+86', code: 'CN', localName: '中国' },
  { name: 'Christmas Island', dialCode: '+61', code: 'CX', partOf: ['AU'] },
  { name: 'Cocos (Keeling) Islands', dialCode: '+61', code: 'CC', partOf: ['AU'] },
  { name: 'Colombia', dialCode: '+57', code: 'CO' },
  { name: 'Comoros', dialCode: '+269', code: 'KM' },
  { name: 'Congo', dialCode: '+242', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', dialCode: '+243', code: 'CD' },
  { name: 'Cook Islands', dialCode: '+682', code: 'CK' },
  { name: 'Costa Rica', dialCode: '+506', code: 'CR' },
  { name: "Cote d'Ivoire", dialCode: '+225', code: 'CI', localName: "Côte d'Ivoire" },
  { name: 'Croatia', dialCode: '+385', code: 'HR', localName: 'Hrvatska' },
  { name: 'Cuba', dialCode: '+53', code: 'CU' },
  { name: 'Curaçao', dialCode: '+599', code: 'CW', partOf: ['NL'] },
  { name: 'Cyprus', dialCode: '+357', code: 'CY' },
  { name: 'Czechia', dialCode: '+420', code: 'CZ', localName: 'Česko' },
  { name: 'Canary Islands', dialCode: '+34', code: 'IC', partOf: ['ES'] },
  { name: 'Djibouti', dialCode: '+253', code: 'DJ', localName: 'جيبوتي' },
  { name: 'Dominica', dialCode: '+1767', code: 'DM' },
  {
    name: 'Dominican Republic',
    dialCode: '+1849',
    code: 'DO',
    countryCode: '+1',
    areaCodes: ['849', '829', '809'],
  },
  { name: 'Dominican Republic', dialCode: '+1829', code: 'DO', secondary: true },
  { name: 'Dominican Republic', dialCode: '+1809', code: 'DO', secondary: true },
  { name: 'Ecuador', dialCode: '+593', code: 'EC' },
  { name: 'Egypt', dialCode: '+20', code: 'EG', localName: 'مصر' },
  { name: 'El Salvador', dialCode: '+503', code: 'SV' },
  { name: 'Equatorial Guinea', dialCode: '+240', code: 'GQ' },
  { name: 'Eritrea', dialCode: '+291', code: 'ER' },
  { name: 'Estonia', dialCode: '+372', code: 'EE' },
  { name: 'Eswatini', dialCode: '+268', code: 'SZ' },
  { name: 'Ethiopia', dialCode: '+251', code: 'ET', localName: 'ኢትዮጵያ' },
  {
    name: 'Falkland Islands (Malvinas)',
    dialCode: '+500',
    code: 'FK',
    partOf: ['GB'],
  },
  { name: 'Faroe Islands', dialCode: '+298', code: 'FO', partOf: ['DK'] },
  { name: 'Fiji', dialCode: '+679', code: 'FJ' },
  { name: 'French Guiana', dialCode: '+594', code: 'GF', partOf: ['FR'] },
  { name: 'French Polynesia', dialCode: '+689', code: 'PF', partOf: ['FR'] },
  {
    name: 'French Southern Territories',
    dialCode: '+262',
    code: 'TF',
    partOf: ['FR'],
  },
  { name: 'Gabon', dialCode: '+241', code: 'GA' },
  { name: 'Gambia', dialCode: '+220', code: 'GM' },
  { name: 'Georgia', dialCode: '+995', code: 'GE', localName: 'საქართველო' },
  { name: 'Ghana', dialCode: '+233', code: 'GH' },
  { name: 'Gibraltar', dialCode: '+350', code: 'GI', partOf: ['GB'] },
  { name: 'Greece', dialCode: '+30', code: 'GR', localName: 'Ελλάς' },
  { name: 'Greenland', dialCode: '+299', code: 'GL', partOf: ['DK'] },
  { name: 'Grenada', dialCode: '+1473', code: 'GD' },
  { name: 'Guadeloupe', dialCode: '+590', code: 'GP', partOf: ['FR'] },
  { name: 'Guam', dialCode: '+1671', code: 'GU', partOf: ['US'] },
  { name: 'Guatemala', dialCode: '+502', code: 'GT' },
  { name: 'Guernsey', dialCode: '+44', code: 'GG', partOf: ['GB'] },
  { name: 'Guinea', dialCode: '+224', code: 'GN' },
  { name: 'Guinea-Bissau', dialCode: '+245', code: 'GW' },
  { name: 'Guyana', dialCode: '+592', code: 'GY' },
  { name: 'Haiti', dialCode: '+509', code: 'HT' },
  { name: 'Heard & McDonald Islands', dialCode: '+672', code: 'HM', partOf: ['AU'] },
  { name: 'Holy See (Vatican City State)', dialCode: '+379', code: 'VA' },
  { name: 'Honduras', dialCode: '+504', code: 'HN' },
  {
    name: 'Hong Kong',
    dialCode: '+852',
    code: 'HK',
    localName: '香港',
    partOf: ['CN'],
  },
  { name: 'Hungary', dialCode: '+36', code: 'HU', localName: 'Magyarország' },
  { name: 'Iceland', dialCode: '+354', code: 'IS', localName: 'Ísland' },
  { name: 'India', dialCode: '+91', code: 'IN', localName: 'भारत' },
  { name: 'Indonesia', dialCode: '+62', code: 'ID' },
  {
    name: 'Iran, Islamic Republic of',
    dialCode: '+98',
    code: 'IR',
    localName: 'ایران',
  },
  { name: 'Iraq', dialCode: '+964', code: 'IQ', localName: 'العراق' },
  { name: 'Ireland', dialCode: '+353', code: 'IE', localName: 'Éire' },
  { name: 'Isle of Man', dialCode: '+44', code: 'IM', partOf: ['GB'] },
  { name: 'Israel', dialCode: '+972', code: 'IL' },
  { name: 'Italy', dialCode: '+39', code: 'IT', localName: 'Italia' },
  {
    name: 'Jamaica',
    dialCode: '+1876',
    code: 'JM',
    countryCode: '+1',
    areaCodes: ['876', '658'],
  },
  { name: 'Jamaica', dialCode: '+1658', code: 'JM', secondary: true },
  { name: 'Japan', dialCode: '+81', code: 'JP', localName: '日本' },
  { name: 'Jersey', dialCode: '+44', code: 'JE', partOf: ['GB'] },
  { name: 'Jordan', dialCode: '+962', code: 'JO', localName: 'الأردن' },
  { name: 'Kazakhstan', dialCode: '+77', code: 'KZ' },
  { name: 'Kenya', dialCode: '+254', code: 'KE' },
  { name: 'Kiribati', dialCode: '+686', code: 'KI' },
  { name: "Korea, Democratic People's Republic of", dialCode: '+850', code: 'KP' },
  { name: 'Korea, Republic of', dialCode: '+82', code: 'KR' },
  { name: 'Kuwait', dialCode: '+965', code: 'KW' },
  { name: 'Kyrgyzstan', dialCode: '+996', code: 'KG' },
  { name: 'Kosovo', dialCode: '+383', code: 'XK' },
  {
    name: "Lao People's Democratic Republic",
    dialCode: '+856',
    code: 'LA',
    localName: 'ປະເທດລາວ',
  },
  { name: 'Latvia', dialCode: '+371', code: 'LV' },
  { name: 'Lebanon', dialCode: '+961', code: 'LB' },
  { name: 'Lesotho', dialCode: '+266', code: 'LS' },
  { name: 'Liberia', dialCode: '+231', code: 'LR' },
  { name: 'Libya', dialCode: '+218', code: 'LY' },
  { name: 'Liechtenstein', dialCode: '+423', code: 'LI' },
  { name: 'Lithuania', dialCode: '+370', code: 'LT' },
  { name: 'Luxembourg', dialCode: '+352', code: 'LU' },
  { name: 'Macao', dialCode: '+853', code: 'MO', partOf: ['CN'] },
  { name: 'Madagascar', dialCode: '+261', code: 'MG' },
  { name: 'Malawi', dialCode: '+265', code: 'MW' },
  { name: 'Malaysia', dialCode: '+60', code: 'MY' },
  { name: 'Maldives', dialCode: '+960', code: 'MV' },
  { name: 'Mali', dialCode: '+223', code: 'ML' },
  { name: 'Malta', dialCode: '+356', code: 'MT' },
  { name: 'Marshall Islands', dialCode: '+692', code: 'MH' },
  { name: 'Martinique', dialCode: '+596', code: 'MQ', partOf: ['FR'] },
  { name: 'Mauritania', dialCode: '+222', code: 'MR' },
  { name: 'Mauritius', dialCode: '+230', code: 'MU' },
  { name: 'Mayotte', dialCode: '+262', code: 'YT', partOf: ['FR'] },
  { name: 'Mexico', dialCode: '+52', code: 'MX' },
  { name: 'Micronesia, Federated States of', dialCode: '+691', code: 'FM' },
  { name: 'Moldova, Republic of', dialCode: '+373', code: 'MD' },
  { name: 'Monaco', dialCode: '+377', code: 'MC' },
  { name: 'Mongolia', dialCode: '+976', code: 'MN' },
  { name: 'Montenegro', dialCode: '+382', code: 'ME' },
  { name: 'Montserrat', dialCode: '+1664', code: 'MS' },
  { name: 'Morocco', dialCode: '+212', code: 'MA' },
  { name: 'Mozambique', dialCode: '+258', code: 'MZ', localName: 'Moçambique' },
  { name: 'Myanmar', dialCode: '+95', code: 'MM', localName: 'မြန်မာ' },
  { name: 'Namibia', dialCode: '+264', code: 'NA' },
  { name: 'Nauru', dialCode: '+674', code: 'NR' },
  { name: 'Nepal', dialCode: '+977', code: 'NP', localName: 'नेपाल' },
  { name: 'Caribbean Netherlands', dialCode: '+599', code: 'BQ', partOf: ['NL'] },
  { name: 'New Caledonia', dialCode: '+687', code: 'NC', partOf: ['FR'] },
  { name: 'New Zealand', dialCode: '+64', code: 'NZ' },
  { name: 'Nicaragua', dialCode: '+505', code: 'NI' },
  { name: 'Niger', dialCode: '+227', code: 'NE' },
  { name: 'Nigeria', dialCode: '+234', code: 'NG' },
  { name: 'Niue', dialCode: '+683', code: 'NU' },
  { name: 'Norfolk Island', dialCode: '+672', code: 'NF', partOf: ['AU'] },
  { name: 'North Macedonia', dialCode: '+389', code: 'MK' },
  { name: 'Northern Mariana Islands', dialCode: '+1670', code: 'MP', partOf: ['US'] },
  { name: 'Oman', dialCode: '+968', code: 'OM', localName: 'عُمان' },
  { name: 'Pakistan', dialCode: '+92', code: 'PK', localName: 'پاکستان' },
  { name: 'Palau', dialCode: '+680', code: 'PW' },
  { name: 'Palestine, State of', dialCode: '+970', code: 'PS' },
  { name: 'Panama', dialCode: '+507', code: 'PA' },
  { name: 'Papua New Guinea', dialCode: '+675', code: 'PG' },
  { name: 'Paraguay', dialCode: '+595', code: 'PY' },
  { name: 'Peru', dialCode: '+51', code: 'PE' },
  { name: 'Philippines', dialCode: '+63', code: 'PH' },
  { name: 'Pitcairn', dialCode: '+872', code: 'PN', partOf: ['GB'] },
  { name: 'Poland', dialCode: '+48', code: 'PL' },
  { name: 'Portugal', dialCode: '+351', code: 'PT' },
  {
    name: 'Puerto Rico',
    dialCode: '+1939',
    code: 'PR',
    countryCode: '+1',
    areaCodes: ['939', '787'],
    partOf: ['US'],
  },
  {
    name: 'Puerto Rico',
    dialCode: '+1787',
    code: 'PR',
    secondary: true,
    partOf: ['US'],
  },
  { name: 'Qatar', dialCode: '+974', code: 'QA', localName: 'قطر' },
  { name: 'Romania', dialCode: '+40', code: 'RO' },
  { name: 'Russia', dialCode: '+7', code: 'RU', localName: 'Россия' },
  { name: 'Rwanda', dialCode: '+250', code: 'RW' },
  { name: 'Réunion', dialCode: '+262', code: 'RE', partOf: ['FR'] },
  { name: 'Saint Barthélemy', dialCode: '+590', code: 'BL', partOf: ['FR'] },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    dialCode: '+290',
    code: 'SH',
    partOf: ['GB'],
  },
  { name: 'Saint Kitts and Nevis', dialCode: '+1869', code: 'KN' },
  { name: 'Saint Lucia', dialCode: '+1758', code: 'LC' },
  { name: 'Saint Martin', dialCode: '+590', code: 'MF', partOf: ['FR'] },
  { name: 'Saint Pierre and Miquelon', dialCode: '+508', code: 'PM', partOf: ['FR'] },
  { name: 'Saint Vincent and the Grenadines', dialCode: '+1784', code: 'VC' },
  { name: 'Samoa', dialCode: '+685', code: 'WS' },
  { name: 'San Marino', dialCode: '+378', code: 'SM' },
  { name: 'Sao Tome and Principe', dialCode: '+239', code: 'ST' },
  { name: 'Saudi Arabia', dialCode: '+966', code: 'SA' },
  { name: 'Senegal', dialCode: '+221', code: 'SN' },
  { name: 'Serbia', dialCode: '+381', code: 'RS' },
  { name: 'Seychelles', dialCode: '+248', code: 'SC' },
  { name: 'Sierra Leone', dialCode: '+232', code: 'SL' },
  { name: 'Singapore', dialCode: '+65', code: 'SG' },
  { name: 'Sint Maarten', dialCode: '+1721', code: 'SX', partOf: ['NL'] },
  { name: 'Slovakia', dialCode: '+421', code: 'SK' },
  { name: 'Slovenia', dialCode: '+386', code: 'SI' },
  { name: 'Solomon Islands', dialCode: '+677', code: 'SB' },
  { name: 'Somalia', dialCode: '+252', code: 'SO', localName: 'الصومال' },
  { name: 'South Africa', dialCode: '+27', code: 'ZA' },
  {
    name: 'South Georgia and the South Sandwich Islands',
    dialCode: '+500',
    code: 'GS',
    partOf: ['GB'],
  },
  { name: 'South Sudan', dialCode: '+211', code: 'SS' },
  { name: 'Spain', dialCode: '+34', code: 'ES' },
  { name: 'Sri Lanka', dialCode: '+94', code: 'LK', localName: 'ශ්‍රී ලංකාව' },
  { name: 'Sudan', dialCode: '+249', code: 'SD', localName: 'السودان' },
  { name: 'Suriname', dialCode: '+597', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', dialCode: '+47', code: 'SJ', partOf: ['NO'] },
  { name: 'Switzerland', dialCode: '+41', code: 'CH' },
  { name: 'Syrian Arab Republic', dialCode: '+963', code: 'SY', localName: 'سورية' },
  { name: 'Taiwan, Province of China', dialCode: '+886', code: 'TW' },
  { name: 'Tajikistan', dialCode: '+992', code: 'TJ' },
  { name: 'Tanzania, United Republic of', dialCode: '+255', code: 'TZ' },
  { name: 'Thailand', dialCode: '+66', code: 'TH', localName: 'เมืองไทย' },
  { name: 'Timor-Leste', dialCode: '+670', code: 'TL' },
  { name: 'Togo', dialCode: '+228', code: 'TG' },
  { name: 'Tokelau', dialCode: '+690', code: 'TK' },
  { name: 'Tonga', dialCode: '+676', code: 'TO' },
  { name: 'Trinidad and Tobago', dialCode: '+1868', code: 'TT' },
  { name: 'Tunisia', dialCode: '+216', code: 'TN', localName: 'تونس' },
  { name: 'Turkey', dialCode: '+90', code: 'TR', localName: 'Türkiye' },
  { name: 'Turkmenistan', dialCode: '+993', code: 'TM' },
  { name: 'Turks and Caicos Islands', dialCode: '+1649', code: 'TC', partOf: ['GB'] },
  { name: 'Tuvalu', dialCode: '+688', code: 'TV' },
  { name: 'Uganda', dialCode: '+256', code: 'UG' },
  { name: 'Ukraine', dialCode: '+380', code: 'UA' },
  { name: 'United Arab Emirates', dialCode: '+971', code: 'AE' },
  { name: 'United States', dialCode: '+1', code: 'US' },
  { name: 'Uruguay', dialCode: '+598', code: 'UY' },
  { name: 'Uzbekistan', dialCode: '+998', code: 'UZ' },
  { name: 'Vanuatu', dialCode: '+678', code: 'VU' },
  { name: 'Venezuela, Bolivarian Republic of', dialCode: '+58', code: 'VE' },
  { name: 'Vietnam', dialCode: '+84', code: 'VN', localName: 'Việt Nam' },
  { name: 'Virgin Islands, British', dialCode: '+1284', code: 'VG', partOf: ['GB'] },
  { name: 'Virgin Islands, U.S.', dialCode: '+1340', code: 'VI', partOf: ['US'] },
  { name: 'Wallis and Futuna', dialCode: '+681', code: 'WF' },
  { name: 'Western Sahara', dialCode: '+212', code: 'EH' },
  { name: 'Yemen', dialCode: '+967', code: 'YE', localName: 'اليمن' },
  { name: 'Zambia', dialCode: '+260', code: 'ZM' },
  { name: 'Zimbabwe', dialCode: '+263', code: 'ZW' },
  { name: 'Åland Islands', dialCode: '+358', code: 'AX', partOf: ['FI'] },
];
