import { useEffect } from 'react';

export const useIOSfont = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const runningInApp = searchParams.get('mm_app');

  useEffect(() => {
    if (runningInApp === 'vsios') {
      const temp = document.createElement('div');
      temp.style.cssText = `
          font: -apple-system-body;
          padding: 0;
          margin: 0;
          width: auto;
          height: auto;
          position: absolute;
          left: -9999px;
          top: -9999px;
          visibility: hidden;
        `;
      temp.textContent = 'X';
      document.body.appendChild(temp);

      const computedSize = window.getComputedStyle(temp).fontSize;
      const initialSystemFontSize = parseFloat(computedSize);

      let textSizeAdjust = 100;

      if (initialSystemFontSize > 21) {
        textSizeAdjust = 62.5;
      }
      if (initialSystemFontSize > 17 && initialSystemFontSize <= 19) {
        textSizeAdjust = 81;
      }
      if (initialSystemFontSize > 19 && initialSystemFontSize <= 21) {
        textSizeAdjust = 71;
      }

      document.documentElement.style.setProperty('-webkit-text-size-adjust', `${textSizeAdjust}%`);

      // Cleanup
      document.body.removeChild(temp);
    }
  }, [runningInApp]);
};
