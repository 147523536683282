import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Icon, LoadingSpinner } from 'mypages-library';
import { useAuthenticate } from 'common/hooks/useAuthenticate';
import { useInitialize } from 'common/hooks/useInitialize';
import { useAlternateSubsidiary } from 'common/hooks/useAlternateSubsidiary';
import { t } from 'i18n/translate';
import { Input } from 'components/Input/Input';
import LanguageMenu from 'components/LanguageMenu/LanguageMenu';
import UnsupportedBrowser from 'components/UnsupportedBrowser/UnsupportedBrowser';
import { browserIsUnsupported } from 'components/UnsupportedBrowser/unsupportedBrowserUtils';
import CookieInfo from 'components/common/CookieInfo/CookieInfo';
import SimpleModal from 'components/SimpleModal/SimpleModal';
import SubsidiaryAppLogo from 'components/SubsidiaryAppLogo/SubsidiaryAppLogo';
import PasswordInput from 'components/Input/PasswordInput';
import useGetDealerSettings from 'common/hooks/useGetDealerSettings';
import AppStore from '../AppStore/AppStore';
import styles from './Login.module.scss';

function Login({ setIsMultiFactorAuthentication, emailController }) {
  const { email, setEmail } = emailController;
  const [password, setPassword] = useState('');
  const { i18n } = useTranslation();
  const language = i18n.language || 'se';
  const { isMultiFactorAuthentication, isLoading, errorBody, authenticate } = useAuthenticate();
  const [appLinks, setAppLinks] = useState('');
  const [modalState, setModalState] = useState(false);
  const [successfulLogin, setSuccessfulLogin] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const { subsidiary } = useAlternateSubsidiary();
  const { dealerSettings } = useGetDealerSettings();
  const { webshopUrl } = dealerSettings;

  const onCloseModal = () => {
    setModalState(false);
  };

  const handleModal = () => {
    if (errorBody.errorCode === 'AUT_00001' && errorBody.verified !== 'false' && attempts === 1) {
      setModalState(true);
    }
  };

  const { inapp } = useInitialize();
  const runningInApp = sessionStorage.getItem('runningInApp') === 'true' || inapp === true;
  const isUnsupportedBrowser = browserIsUnsupported();
  const login = () => authenticate(email, password, setSuccessfulLogin);
  const handleEnterKey = event => {
    if (event.key === 'Enter' && !isLoading) {
      login();
    }
  };

  useEffect(() => {
    if (successfulLogin) {
      setAttempts(0);
      setSuccessfulLogin(false);
    }
  }, [successfulLogin]);

  useEffect(() => {
    if (!runningInApp) {
      const links = (
        <>
          <hr className={styles.divider} />
          <p className={styles['download-title']}>{t('MPL_DOWNLOAD_APP')}</p>

          <AppStore />
        </>
      );
      setAppLinks(links);
    }
  }, [runningInApp, language]);

  useEffect(() => {
    if (errorBody) {
      setPassword('');
      handleModal();
      setAttempts(current => current + 1);
    }
  }, [errorBody]);

  useEffect(() => {
    setAttempts(0);
  }, [email]);

  useEffect(() => {
    document.addEventListener('keydown', handleEnterKey, { once: true });

    return () => document.removeEventListener('keydown', handleEnterKey);
  });

  useEffect(() => {
    setIsMultiFactorAuthentication(isMultiFactorAuthentication);
  }, [isMultiFactorAuthentication, setIsMultiFactorAuthentication]);

  return (
    <>
      <div className={styles['login-wrapper']}>
        <main id="LoginPage" className={styles['login-content']}>
          {isLoading && <LoadingSpinner isLoading centered size="large" dataTestId="spinner" />}
          <SubsidiaryAppLogo />
          {isUnsupportedBrowser ? (
            <UnsupportedBrowser />
          ) : (
            <>
              <Input
                disableLabelAnimation
                type="text"
                placeholder={t('MPL_LOGIN_PLACEHOLDER_EMAIL')}
                onChange={value => {
                  setEmail(value.trim());
                }}
                value={email}
                maxLength={255}
                className={styles['login-field']}
                dataTestId="mypages-login-email"
                autoComplete="username"
                minLengthErrorText={t('MPL_NOT_ENOUGH_CHARACTERS_ERROR')}
                mandatoryInfoText={t('MPL_ETC_MANDATORY_INPUT_FIELD')}
                focus
              />
              <PasswordInput
                disableLabelAnimation
                placeholder={t('MPL_LOGIN_PLACEHOLDER_PASSWORD')}
                onChange={value => setPassword(value)}
                value={password}
                maxLength={30}
                className={styles['login-field']}
                dataTestId="mypages-login-password"
                autoComplete="current-password"
              />
              {modalState && (
                <SimpleModal
                  onClose={onCloseModal}
                  modalState={modalState}
                  dataTestId="mypages-login-modal"
                  title={t('MPL_FORGOT_PASSWORD_WARNING_TITLE')}
                  text={t('MPL_FORGOT_PASSWORD_WARNING_TEXT')}
                  question={t('MPL_FORGOT_PASSWORD_WARNING_QUESTION')}
                  confirmBtn={t('MPL_FORGOT_PASSWORD_WARNING_CONFIRM_BUTTON')}
                  cancelBtn={t('MPL_FORGOT_PASSWORD_WARNING_CANCEL_BUTTON')}
                />
              )}
              <a
                id="loginForgotPassword"
                href={`/${language}/forgotpassword.html`}
                className={styles['forgot-pswd-link']}
              >
                {t('MPL_FORGOT_PASSWORD')}
              </a>
              {errorBody && <div className={styles['error-message']}>{errorBody.message}</div>}
              <Button
                text={t('MPL_LOGIN')}
                className={styles['login-button']}
                red
                onClick={login}
                disabled={isLoading}
                dataTestId="mypages-login-submit"
              />
            </>
          )}
          <p id="loginSignUp" className={styles['sign-up-text']}>
            {t('MPL_MISS_ACCOUNT')} <a href={`/${language}/createaccount.html`}> {t('MPL_SIGN_UP')}</a>
          </p>
          <div className={styles.links}>{appLinks}</div>
        </main>

        {subsidiary ? null : (
          <footer id="footer" className={styles.footer}>
            <LanguageMenu />
            {webshopUrl && (
              <a href={webshopUrl}>
                <Icon icon="icon-webshop" className={styles['webshop-icon']} />
                {t('MPL_WEBSHOP')}
              </a>
            )}
          </footer>
        )}
      </div>
      <CookieInfo />
    </>
  );
}

Login.propTypes = {
  setIsMultiFactorAuthentication: PropTypes.func.isRequired,
  emailController: PropTypes.shape({
    email: PropTypes.string.isRequired,
    setEmail: PropTypes.func.isRequired,
  }).isRequired,
};

Login.whyDidYouRender = {
  customName: 'Login',
};

export default Login;
