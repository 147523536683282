import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, ga } from 'mypages-library';
import { t } from 'i18n/translate';

import LanguageSelectButton from 'components/common/LanguageSelectButton/LanguageSelectButton';
import { siteLanguages } from 'common/languageUtil';

import styles from './LanguageMenu.module.scss';

export default function LanguageMenu() {
  const { i18n } = useTranslation();
  const [menuExpanded, setMenuExpanded] = useState(false);

  const track = (eventAction, eventLabel = 'new login') => {
    ga.trackEvent({
      eventCategory: 'Language selector',
      eventAction,
      eventLabel,
    });
  };

  const changeLanguage = lang => {
    i18n.changeLanguage(lang);
    setMenuExpanded(false);
    track(`changed language to ${lang}`);
  };
  const languageList = siteLanguages.map(lang => (
    <button key={lang} onClick={() => changeLanguage(lang)} className={styles.language} type="button">
      {t(`MPL_ETC_LANGUAGE_${lang.toUpperCase()}`)}
      {lang === i18n.language && <Icon icon="icon-dot" className={styles.selected} />}
    </button>
  ));
  if (sessionStorage.getItem('mm_app')) {
    return null;
  }
  return (
    <LanguageSelectButton
      label={t(`MPL_ETC_LANGUAGE_${i18n.language.toUpperCase()}`)}
      className={styles['language-menu']}
      menuController={{ menuExpanded, setMenuExpanded }}
      dataTestId="languageSelect"
    >
      {languageList}
      <p className={styles['language-description']}>{t('MPL_LANGUAGE_DESCRIPTION')}</p>
    </LanguageSelectButton>
  );
}
