import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { appConstants, Icon, PopOver } from 'mypages-library';
import { countries, Country } from 'utils/countries';
import PhoneSelectButton from 'components/common/PhoneSelectButton/PhoneSelectButton';
import { t } from 'i18n/translate';
import { Input } from 'components/Input/Input';
import { useTrackPopOver } from 'common/useTrackPopOver';
import Flag from 'components/common/Flag/Flag';
import styles from './InternationalPhonePicker.module.scss';

interface CountryDropdownPickerProps {
  phoneNumber: string;
  setPhoneNumber: (dialCode: string) => void;
  phoneError: boolean;
  selectedCountryPhone: Country | null;
  setSelectedCountryPhone: (country: Country) => void;
  disabled: boolean;
}

const InternationalPhonePicker: React.FC<CountryDropdownPickerProps> = ({
  phoneNumber,
  setPhoneNumber,
  phoneError,
  selectedCountryPhone,
  setSelectedCountryPhone,
  disabled,
}) => {
  const phoneErrorMsg = phoneError ? t('MPL_INVALID_PHONE_NUMBER') : '';
  const [menuExpanded, setMenuExpanded] = useState(false);
  const { PHONE_MAX_LENGTH, PHONE_MIN_LENGTH } = appConstants;
  const sortedCountries = countries.sort((a, b) => {
    if (a.preferred && !b.preferred) return -1;
    if (!a.preferred && b.preferred) return 1;
    return 0;
  });

  const examplePhoneNumber = () => {
    if (phoneNumber.length !== 0) {
      return '';
    }
    return selectedCountryPhone?.example ?? '';
  };

  const { infoTextVisible, onClickInfo } = useTrackPopOver();
  const handleCountryChange = (country: Country) => {
    setSelectedCountryPhone(country);
    setMenuExpanded(false);
  };

  const buttonRefs = useRef<{ [key: string]: HTMLButtonElement | null }>({});

  useEffect(() => {
    if (selectedCountryPhone) {
      const selectedButton = buttonRefs.current[`${selectedCountryPhone.dialCode}-${selectedCountryPhone.code}`];

      if (selectedButton) {
        selectedButton.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [selectedCountryPhone, menuExpanded]);

  let hasInsertedSeparator = false;
  return (
    <div className={styles.contentWrapper}>
      {disabled && (
        <div className={styles.infoText}>
          <div className={styles.text}>{t('MPL_CREATE_ACCOUNT_MOBILE_PHONE_NON_EDITABLE_HEADER')}</div>
          <div className={styles.popoverContainer}>
            <Icon
              icon="icon-help-contextual"
              size="small"
              className={styles.icon}
              onClick={onClickInfo}
              tabIndex={-1}
              dataTestId="phonenumber-not-editable-icon"
            />
            {infoTextVisible && (
              <PopOver
                visible={infoTextVisible}
                className={classNames(styles.popover, infoTextVisible && styles.popoverVisible)}
              >
                <p className={styles.infoText} id="phonenumber-not-editable-text">
                  {t('MPL_CREATE_ACCOUNT_MOBILE_PHONE_NON_EDITABLE_TEXT')}
                </p>
              </PopOver>
            )}
          </div>
        </div>
      )}

      <div className={styles.inputWrapper}>
        {!disabled && (
          <PhoneSelectButton country={selectedCountryPhone} menuController={{ menuExpanded, setMenuExpanded }}>
            {sortedCountries.map((country: Country) => {
              const isNonPreferred = !country.preferred;
              const shouldInsertSeparator = isNonPreferred && !hasInsertedSeparator;
              if (shouldInsertSeparator) {
                hasInsertedSeparator = true;
              }

              return (
                <React.Fragment key={`${country.code}-${country.dialCode}`}>
                  {shouldInsertSeparator && <div className={styles.separator} />}
                  <button
                    ref={el => (buttonRefs.current[`${country.dialCode}-${country.code}`] = el)}
                    onClick={() => handleCountryChange(country)}
                    value={country.dialCode}
                    type="button"
                    tabIndex={-1}
                  >
                    <div className={styles.countryContainer}>
                      <div
                        className={classNames(
                          country.code === selectedCountryPhone?.code &&
                            country.dialCode === selectedCountryPhone?.dialCode &&
                            styles.countrySelected
                        )}
                      >
                        <Flag country={country.code} lazyLoadImgWhenScolling />
                        <span>
                          {country.name} {country.dialCode}
                        </span>
                      </div>
                      <div className={styles.countrySelected}>
                        {country.code === selectedCountryPhone?.code &&
                          country.dialCode === selectedCountryPhone?.dialCode &&
                          '✓'}
                      </div>
                    </div>
                  </button>
                </React.Fragment>
              );
            })}
          </PhoneSelectButton>
        )}
        <div className={styles.inputField}>
          <Input
            placeholder={examplePhoneNumber()}
            value={phoneNumber}
            dataTestId="PhoneNumberInput"
            maxLength={PHONE_MAX_LENGTH}
            minLength={PHONE_MIN_LENGTH}
            type="tel"
            onChange={value => setPhoneNumber(value.replace(/\D/g, ''))}
            hasError={phoneError}
            errorMessage={phoneErrorMsg}
            className={styles.phoneInput}
            disabled={disabled}
          />
        </div>
        <label className={styles.phoneNumberLabel}>{t('MPL_CREATE_ACCOUNT_MOBILE_PHONE')}</label>
      </div>
    </div>
  );
};

export default InternationalPhonePicker;
